import React, {Component} from 'react'
import {base} from '../../base';
import appBackgroundImage from '../../styles/images/trivia_background.png';
import '../../styles/css/CreatorAwards.css';
// import AnswerItemsComponent from "../small_components/AnswerItemsComponent";
import posed, {PoseGroup} from "react-pose";
import sampleScoreboardTopImage from "../../styles/images/top_scoreboard.png";
import '../../styles/css/CustomScoreboard.css';
import {Textfit} from "react-textfit";
// import Loading from "../utils/Loading";

const Trans = posed.div({
    enter: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: ({ delta }) => - delta * 100 + 'vw' }
})

class CustomScoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frozenVotingState: [],
            answers: [],
            questions: [],
            question: 0,
            currentGame: {},
            isVisible: false,
            rotate: false,
            questionAnswered: false,
            userAnswers: [],
            startAnimating: true,
            loading: true
            // triviaGameOn: false,
        };
    }

    componentDidMount() {
        this.currentGameRef = base.bindToState('currentGame', {
          context: this,
          state: 'currentGame',
        })
        this.questionAnsweredRef = base.bindToState('currentGame/answered', {
          context: this,
          state: 'questionAnswered',
        })

        this.questionTextRef = base.bindToState(`currentGame/questions`, {
          context: this,
          state: 'questions'
        });

        this.userAnswersRef = base.bindToState(`userAnswers`, {
            context: this,
            state: 'userAnswers',
            asArray: true,
            then(data){
                this.setState({
                    loading:false
                })
            }
        });
    }

    setUpQuestionsWithAnswerCount(userAnswers, questions){
        let finalQuestionTally = {};
        let questionIdArray = [];
        for(let questionIndex in questions){
            let question = questions[questionIndex];
            let questionId = question.id;
            finalQuestionTally[questionId] = {};
            finalQuestionTally[questionId].totalPicks = 0;
            finalQuestionTally[questionId].answers = {};
            let questionAnswers = question.answers;
            for(let answerIndex in questionAnswers){
                let answer = questionAnswers[answerIndex];
                let answerId = answer.id;
                finalQuestionTally[questionId].answers[answerId] = 0;
            }
            questionIdArray.push(questions[questionIndex].id);
        }
        if(userAnswers.length > 0) {
            for(let answerIndex in userAnswers) {
                let answerObject = userAnswers[answerIndex];
                for(let questionIndex in questionIdArray){
                    let questionId = questionIdArray[questionIndex];
                    if(answerObject[questionId]){
                        finalQuestionTally[questionId].totalPicks++;
                        let answerId =  answerObject[questionId];
                        finalQuestionTally[questionId].answers[answerId]++;
                    };
                }
            }
        }
        return finalQuestionTally;
    }

    handleImageLoaded(e) {
        if(e.target.id === "topImageScoreboard"){
            this.setState({
                topImageWidth: e.target.width
            })
        } else {
            this.setState({
                answerImageLoaded: true
            })
        }
    }

    handleImageErrored() {
      console.log("Image failed to load")
      this.setState({ loading: false });
    }

    componentWillUnmount() {
        base.removeBinding(this.questionTextRef);
        base.removeBinding(this.questionAnsweredRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.userAnswersRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.currentGame.questionToShow === this.state.currentGame.questionToShow - 1){
            this.setState({startAnimating:false}, ()=>{
                this.setState({startAnimating:true})
            })
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const currentGame = this.state.currentGame;
        const questions = currentGame.questions || [];
        let questionCount = {};
        let currentQuestion = {};
        let itemsToIterate = [];
        if(questions.length > 0){
            currentQuestion = questions[currentGame.questionToShow] || questions[0];
            questionCount = this.setUpQuestionsWithAnswerCount(this.state.userAnswers, questions)
            itemsToIterate = currentQuestion.answers;
        }
        let maxWidthOfEverything = 0;
        let addUpBodyHeight = 0;
        let body = document.body, html = document.documentElement;
        let gridItemHeight = 0;
        let answerSectionRemainingHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        const topImageScoreboardElement = document.getElementById('topImageScoreboard');
        let topScoreboardHeight = 0;
        if(topImageScoreboardElement){
            maxWidthOfEverything = topImageScoreboardElement.width;
            topScoreboardHeight = Math.max( topImageScoreboardElement.height, topImageScoreboardElement.offsetHeight, topImageScoreboardElement.scrollHeight);
            addUpBodyHeight = topScoreboardHeight+(answerSectionRemainingHeight-answerSectionRemainingHeight*.8)
            answerSectionRemainingHeight -= addUpBodyHeight;
            gridItemHeight = (answerSectionRemainingHeight/itemsToIterate.length) - 20;
            if(gridItemHeight > addUpBodyHeight){
                gridItemHeight = topScoreboardHeight;
            } else if(gridItemHeight < 0){
                console.log("trouble getting element")
                gridItemHeight = 100
            }
        }
        const backgroundImage = tenantVariables.scoreboardBackgroundImage || appBackgroundImage;
        const topOfScoreboardImage = tenantVariables.topOfScoreboardImage || sampleScoreboardTopImage;
        return (
            <div className="flex-container-scoreboard" style={{backgroundImage: "url(" + backgroundImage + ")"}}>
                <div className="grid-wrapper-scoreboard">
                    <div className="">
                        <img src={topOfScoreboardImage} alt="" id="topImageScoreboard" onLoad={this.handleImageLoaded.bind(this)}/>
                    </div>
                    {this.state.topImageWidth &&
                        <Textfit mode="multi" min={34} max={1000} style={{height: "20%", width: this.state.topImageWidth, textShadow: "2px 2px #000", wordWrap: "break-word", fontWeight:700, color:tenantVariables.progressBarQuestionTextColor}}>
                            {currentQuestion.questionText}
                        </Textfit>
                    }
                    {this.state.startAnimating &&
                        <PoseGroup animateOnMount={true} delta={1}>
                            <Trans key={233}>
                                <div className="grid-container-scoreboard" style={{maxWidth: maxWidthOfEverything, gridAutoFlow:"row", transform:"translateY(0%)", padding:0, gridTemplateRows: gridItemHeight, gridAutoRows:gridItemHeight}}>
                                    {
                                        itemsToIterate.sort((a,b)=>a.order-b.order).map(function(item,i){
                                            let container;
                                            if(document.getElementsByClassName('grid-item')){
                                                container = document.getElementsByClassName('grid-item')[0];
                                            }
                                            let progressWidth = "0";
                                            let totalCount = 0;
                                            let totalAnswers = 0;
                                            let percent = "0%";
                                            if(currentQuestion.answers){
                                                totalCount = questionCount[currentQuestion.id].totalPicks;
                                                totalAnswers = questionCount[currentQuestion.id].answers[item.id];
                                            } else {
                                                for(let questionIndex in questionCount){
                                                    for(let answerIndex in questionCount[questionIndex].answers){
                                                        if(item.id === answerIndex){
                                                            totalCount = questionCount[questionIndex].totalPicks
                                                            totalAnswers = questionCount[questionIndex].answers[answerIndex]
                                                        }
                                                    }
                                                }
                                            }
                                            if(container && totalCount>0){
                                                progressWidth = parseInt(container.clientWidth * totalAnswers/totalCount, 10)
                                            }
                                            let progressBarColor = tenantVariables.progressBarColor || "transparent";
                                            let answerTextColor = tenantVariables.progressBarTextColor || "transparent";
                                            let scoreboardBackgroundColor = tenantVariables.progressBarBackgroundColor || "transparent";
                                            if(currentGame.screenToShow === 2 && item.correct){
                                                scoreboardBackgroundColor = tenantVariables.progressBarEndWinColor || "transparent";
                                                progressBarColor = tenantVariables.progressBarEndWinColor || "transparent";
                                            }
                                            if(totalCount > 0){
                                                percent = (parseInt(totalAnswers/totalCount*100,10)).toString()+"%";
                                            }
                                            return <div
                                                key={i}
                                                id={item.id}
                                                // className="grid-item answerItemClass"
                                                style={{display: "flex", flexDirection: "row", background: scoreboardBackgroundColor, color:answerTextColor, boxShadow:"unset", outline:"1px solid "+answerTextColor, minWidth:100, lineHeight:1,border:"none",padding:0,borderRadius:0,zIndex:-2}}>
                                                <div className="progress-button-container" id="progress-button-container" style={{overflow:"visible"}}>
                                                    <div className="progress-container" style={{width:progressWidth, backgroundColor:progressBarColor, zIndex:-1, borderRadius:0}}/>
                                                </div>
                                                <div style={{display:"flex", flexDirection: "row", alignItems: "center", width:"100%"}}>
                                                    {item.answerImage &&
                                                        <div style={{height:"100%", alignItems:"center"}}>
                                                            <img onLoad={this.handleImageLoaded.bind(this)} src={item.answerImage} width={"auto"} height={"auto"} alt="" style={{marginLeft:5, maxHeight:"100%", display:'flex', alignItems:"center"}}/>
                                                        </div>
                                                    }
                                                    <div style={{display:"flex", flexGrow:3, justifyContent: 'flex-start', height: gridItemHeight}}>
                                                        {gridItemHeight && this.state.topImageWidth &&
                                                            <Textfit mode="multi" min={34} max={1000} style={{width:"100%", height: "100%", display:"flex", alignItems:"center", justifyContent: "flex-start", marginLeft:5}}>
                                                                {item.answerText}
                                                            </Textfit>
                                                        }
                                                    </div>
                                                    <div style={{display:"flex", flexGrow:1,justifyContent: 'flex-end'}}>
                                                        {gridItemHeight && this.state.topImageWidth &&
                                                            <Textfit forceSingleModeWidth={false} mode="single" min={34} max={1000} style={{width: "100%", height: gridItemHeight, display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
                                                                {percent}
                                                            </Textfit>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }, this)
                                    }
                                </div>
                            </Trans>
                        </PoseGroup>
                    }
                </div>
            </div>
          )
        }
}

export default CustomScoreboard;
